import { createSlice, createSelector } from '@reduxjs/toolkit';

import { TRootState } from '../../../../store';

export type TGridTypes = 'Tasks' | 'ContractItems' | 'Events' | 'EventsByRfid';

interface IState {
    selectedGrid: null | TGridTypes;
    mode:
        | null
        | 'preview'
        | 'create'
        | 'edit'
        | 'editClient'
        | 'addLocation'
        | 'editLocation';
    selectedItem: string | null;
}

export const initialState: IState = {
    selectedGrid: null,
    mode: null,
    selectedItem: null,
};

const bottomGrid = createSlice({
    name: 'bottomGrid',
    initialState,
    reducers: {
        enterPreviewMode(state) {
            state.mode = 'preview';
        },
        enterCreateMode(state) {
            state.mode = 'create';
        },
        enterEditMode(state, action) {
            state.mode = 'edit';
            state.selectedItem = action.payload;
        },
        enterClientEditMode(state) {
            state.mode = 'editClient';
        },
        enterLocationEditMode(state) {
            state.mode = 'editLocation';
        },
        enterLocationAddMode(state) {
            state.mode = 'addLocation';
        },
        resetMode(state) {
            state.mode = null;
        },
        selectBottomGrid(state, action: { payload: TGridTypes; type: string }) {
            state.selectedGrid = action.payload;
            state.mode = null;
            state.selectedItem = null;
        },
        closeBottomGrid(state) {
            state.selectedGrid = null;
            state.mode = null;
            state.selectedItem = null;
        },
    },
});

const getCurrent = (state: TRootState) =>
    state.ui.customerService.bottomGrid as IState;

export const getBottomGrid = (state: TRootState) =>
    getCurrent(state).selectedGrid;

export const getGridMode = (state: TRootState) => getCurrent(state).mode;

export const bottomGridVisible = createSelector(
    [getGridMode],
    (mode) => !!mode
);

export const isBottomGridInEditMode = createSelector(
    [getGridMode],
    (mode) => mode && mode !== 'preview'
);

export const {
    closeBottomGrid,
    enterPreviewMode,
    enterCreateMode,
    enterClientEditMode,
    enterLocationEditMode,
    enterLocationAddMode,
    enterEditMode,
    selectBottomGrid,
    resetMode,
} = bottomGrid.actions;

export default bottomGrid.reducer;
