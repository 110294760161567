import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import moment from 'moment';

import { IEventByRfidPreFetchParams } from '../../../../../models/customerService';

import { TRootState } from '../../../../../store';

import { fetchCustomSourceSetRawPost } from '../../../../../services/discovery/fetchCustomSourceSet';
import {
    IAttribute,
    ISourceSet,
    ISourceSetEntity,
    TStatus,
} from '../../../../types';

import TimeFormatter from '../../../../../helpers/TimeFormatter';
import { removeDuplicatesById } from '../../../../../helpers/removeDuplicatesById';

import {
    getValidRfidFilter,
    TLocationWithCoordinates,
} from '../../clientsAndLocations';
import { restGetContractItemRfids } from '../../../../../services/customerService/contracts';
import { getSourceSetWithFilter } from '../../../../../pages/discovery/selectors/sourceSet/getSourceSetWithFilter';

interface IState {
    selectedEventId: number | null;
    events: ISourceSet[] | null;
    status: TStatus;
    dates: {
        from: string;
        to: string;
    };
    openSectionEvent: boolean;
}

export const EVENTS_BY_RFID_ID = 'EventsByRfid';

export const initialState: IState = {
    events: null,
    selectedEventId: null,
    status: 'idle',
    openSectionEvent: false,
    dates: {
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
    },
};

export const fetchEventsWithQuery = createAsyncThunk(
    'post:ui/customerService/bottomGrid/eventsByRfid',
    async (params: IEventByRfidPreFetchParams) => {
        const locations = params.selectedClientsAndLocations.filter(
            (item) => item.id
        ) as TLocationWithCoordinates[];

        const { rfidFilter } = params;
        let rfids: string[] = [];

        if (rfidFilter) {
            rfids = [rfidFilter];
        } else if (locations.length) {
            rfids = await restGetContractItemRfids(locations.map((l) => l.id));
        }

        return rfids.length
            ? await Promise.all([
                  fetchCustomSourceSetRawPost({
                      api: `/rest/api/source-sets/archive-events/query`,
                      method: 'POST',
                      label: '',
                      body: {
                          query: {
                              from: TimeFormatter.toISOString(
                                  moment(params.from).startOf('day') ||
                                      moment(new Date()).format('YYYY-MM-DD')
                              ),
                              to: TimeFormatter.toISOString(
                                  moment(params.to).endOf('day') ||
                                      moment(new Date()).format('YYYY-MM-DD')
                              ),
                              rfids: rfids,
                          },
                          response: {
                              limit: 50000,
                              hiddenParams: false,
                          },
                      },
                  }),
              ])
            : null;
    }
);

const eventsByRfid = createSlice({
    name: 'eventsByRfid',
    initialState,
    reducers: {
        selectEvent(state, action) {
            state.selectedEventId = action.payload;
        },

        toggleCollapseTasks(state) {
            state.openSectionEvent = !state.openSectionEvent;
        },
        setDates(state, action) {
            state.dates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventsWithQuery.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchEventsWithQuery.fulfilled, (state, action) => {
            state.events = action.payload;
            state.status = 'done';
        });
    },
});

export const getEvents = (state: TRootState) =>
    state.ui.customerService.eventsByRfid.events;

export const getSelectedEventId = (state: TRootState) =>
    state.ui.customerService.eventsByRfid.selectedEventId;

export const getEventsStatus = (state: TRootState) =>
    state.ui.customerService.eventsByRfid.status;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const getEventDates = (state: TRootState) =>
    state.ui.customerService.eventsByRfid.dates;

export const getOpenSectionEvents = (state: TRootState) =>
    state.ui.customerService.eventsByRfid.openSectionEvent;

export const getEventsSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.eventsByRfid || null
);

function getLongestAttributesList(arr: ISourceSet[]): IAttribute[] {
    if (arr.length === 0) {
        return [];
    }
    const sourceSetWithLongestAttributes = arr.reduce(
        (maxObj, obj) =>
            obj.attributes.length > maxObj.attributes.length ? obj : maxObj,
        arr[0]
    );

    return sourceSetWithLongestAttributes.attributes;
}

export const getEventsAsSourceSet = createSelector(
    [getEvents, getValidRfidFilter],
    (events): ISourceSet | null =>
        events && {
            id: 'eventsByRfid',
            definitionId: 'EventsByRfid',
            label: events[0].label,
            attributes: getLongestAttributesList(events),
            layersAttributes: [],
            _meta: { ...events[0]._meta, geolocated: false },
            entities: removeDuplicatesById(
                events
                    .map((event: ISourceSet): any => {
                        return event.entities.map(
                            (eventValue: ISourceSetEntity): any => {
                                return {
                                    ...eventValue,

                                    _meta: {},
                                };
                            }
                        );
                    })
                    .flat()
            ),
        }
);

export const getEventsSourceSetWithFilter = getSourceSetWithFilter(
    getEventsSourceSetModel,
    getEventsAsSourceSet
);

export const { setDates, selectEvent, toggleCollapseTasks } =
    eventsByRfid.actions;

export default eventsByRfid.reducer;
